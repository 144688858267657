<template lang="">
  <b-card>
    <b-row>
      <b-col cols="6">
        <h3 class="font-weight-bolder">
          Sebaran Per Wilayah
        </h3>
        <br>
        
        <b-overlay
          :show="loading"
          variant="light"
          :opacity="0.5"
          rounded="sm"
        >

          <b-table
            striped
            responsive
            :items="itemsBranch"
            :fields="fieldsTableBranch"
          />

        </b-overlay>
      </b-col>
      <b-col cols="6">
        <template>
          <vue-apex-charts 
            ref="realtimeChartBranch" 
            type="pie" 
            height="350" 
            :options="chartOptionsComputed" 
            :series="dataseriesBranch"
          />
        </template>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <h3 class="font-weight-bolder">
          Sebaran Per Model
        </h3>
        <br>
        
        <b-overlay
          :show="loading"
          variant="light"
          :opacity="0.5"
          rounded="sm"
        >

          <b-table
            striped
            responsive
            :items="itemsModel"
            :fields="fieldsTableModel"
          />

        </b-overlay>
      </b-col>
      <b-col cols="6">
        <template>
          <vue-apex-charts 
            ref="realtimeChartModel" 
            type="pie" 
            height="350" 
            :options="chartOptionsComputed" 
            :series="dataseriesModel"
          />
        </template>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <h3 class="font-weight-bolder">
          Sebaran Per Tahun
        </h3>
        <br>
        
        <b-overlay
          :show="loading"
          variant="light"
          :opacity="0.5"
          rounded="sm"
        >

          <b-table
            striped
            responsive
            :items="itemsYear"
            :fields="fieldsTableYear"
          />

        </b-overlay>
      </b-col>
      <b-col cols="6">
        <template>
          <vue-apex-charts 
            ref="realtimeChartYear" 
            type="pie" 
            height="350" 
            :options="chartOptionsComputed" 
            :series="dataseriesYear"
          />
        </template>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol,BTable,BOverlay } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { pieChartOptions } from '../chartOptions'

export default {
  components: {
    BTable,
    BOverlay,
    BCard,
    BButton,
    BFormGroup,
    VueApexCharts,
    BFormSelect,
    BRow,
    BCol,
  },
  data() {
    return {
      dataurl:"/vehicle/chart",
      fieldsTableBranch: [
        { key: 'branch.name', label: 'Region'},
        { key: 'count', label: 'Jumlah'},
      ],
      fieldsTableModel: [
        { key: 'variant.model', label: 'Model'},
        { key: 'count', label: 'Jumlah'},
      ],
      fieldsTableYear: [
        { key: 'year', label: 'Tahun'},
        { key: 'count', label: 'Jumlah'},
      ],
      itemsBranch:[],
      itemsModel:[],
      itemsYear:[],
      datalabelBranch:[],
      datalabelModel:[],
      datalabelYear:[],
      dataseriesBranch:[],
      dataseriesModel:[],
      dataseriesYear:[],
      loading:false,
    }
  },
  computed: {
    chartOptionsComputed() {
      const options = JSON.parse(JSON.stringify(pieChartOptions))
      return options
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData(){
      this.loading = true
      this.$http.get(this.dataurl)
        .then(res  => {
          this.itemsBranch = res.data.branch
          this.itemsModel = res.data.variant
          this.itemsYear = res.data.year
          this.fetchDataYear(res.data.year)
          this.fetchDataModel(res.data.variant)
          this.fetchDataBranch(res.data.branch)
          
        })
        .catch(()=>{
          this.$bvToast.toast('Something wrong', {
            title: 'Fail',
            solid: true,
            variant:'warning'
          })
        })
        .finally(() => this.loading = false)
    },
    fetchDataBranch(result){
      for(let i = 0; i < result.length; i++){
        if(result[i]['branch'])
          this.datalabelBranch.push(result[i]['branch']['name'])

        this.dataseriesBranch.push(result[i]['count'])
      }
      this.$refs.realtimeChartBranch.updateOptions({ labels: this.datalabelBranch })
    },
    fetchDataModel(result){
      for(let i = 0; i < result.length; i++){
        if(result[i]['variant'])
          this.datalabelModel.push(result[i]['variant']['model'])

        this.dataseriesModel.push(result[i]['count'])
      }
      this.$refs.realtimeChartModel.updateOptions({ labels: this.datalabelModel })
    },
    fetchDataYear(result){
      for(let i = 0; i < result.length; i++){
        this.datalabelYear.push(result[i]['year'])
        this.dataseriesYear.push(result[i]['count'])
      }
      this.$refs.realtimeChartYear.updateOptions({ labels: this.datalabelYear })
    }
  },
  
}
</script>
<style lang="">

</style>
